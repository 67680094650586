
.glitch-text {
  position: relative;

  &__proto {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
  }
}
