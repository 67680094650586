@import "src/styles/mixins";

#fabula-screen {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;

  .columns {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: stretch;

    @include adapt(mobile) {
      flex-direction: column;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-right: 50px;

      @include adapt(mobile) {
        margin-right: 0;
        align-items: center;
        text-align: center;
        margin-bottom: 13px;
      }

      .title {
        font-family: 'quantum', sans-serif;
        font-size: 100px;
        line-height: 80px;
        letter-spacing: -0.1em;

        @include adapt(tablet) {
          font-size: 80px;
          line-height: 60px;
          margin-bottom: 15px;
        }

        @include adapt(mobile) {
          font-size: 40px;
          line-height: 28px;
          margin-bottom: 5px;
        }
      }

      p {
        max-width: 450px;
        font-size: 18px;
        line-height: 28px;

        @include adapt(tablet) {
          font-size: 16px;
          line-height: 22px;
          max-width: 320px;
        }

        @include adapt(mobile) {
          font-size: 14px;
          line-height: 16px;
          max-width: 290px;
        }
      }

      .button-wrap {
        margin-bottom: 25px;

        @include adapt(tablet) {
          margin-bottom: 5px;
        }

        @include adapt(mobile) {
          margin-bottom: 8px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      width: 380px;
      margin-bottom: 15px;
      //animation: slide 3s infinite;

      @keyframes slide {
        0% {
          opacity: 0;
          transform: translateY(100px);
        }
        25% {
          opacity: 1;
          transform: translateY(0);
        }
        75% {
          opacity: 1;
          transform: translateY(0);
        }
        100% {
          opacity: 0;
          transform: translateY(-100px);
        }
      }
      @keyframes slideMobile {
        0% {
          opacity: 0;
          transform: translateY(100px);
        }
        25% {
          opacity: 1;
          transform: translateY(0);
        }
        75% {
          opacity: 1;
          transform: translateY(0);
        }
        90% {
          opacity: 0;
        }
        100% {
          opacity: 0;
          transform: translateY(100px);
        }
      }

      @include adapt(mobile) {
        align-items: center;
        flex-direction: column-reverse;
        text-align: center;
        //animation: slideMobile 3s infinite;
      }

      .types {
        font-size: 30px;
        line-height: 35px;
        font-weight: 300;

        @include adapt(tablet) {
          font-size: 20px;
          line-height: 23px;
          margin-bottom: 16px;
        }

        @include adapt(mobile) {
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 5px;
        }
      }


      .counter {
        font-size: 180px;
        line-height: 130px;
        letter-spacing: -0.2em;
        font-family: 'quantum', sans-serif;

        @include adapt(tablet) {
          font-size: 140px;
          line-height: 80px;
        }

        @include adapt(mobile) {
          font-size: 80px;
          line-height: 60px;
          position: relative;
          left: 0.1em;
        }
      }


    }

  }
}
