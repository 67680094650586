
.sound-bars {
  position: relative;
  display: block;
  width: 34px;
  height: 34px;
  overflow: hidden;

  &__wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    i {
      background: #fff;
      display: block;
      width: 1px;
      height: 100%;
      margin-left: 2px;
      min-height: 4px;
      transition: height 1000ms ease;

      @for $idx from 1 to 13 {
        &:nth-of-type(#{$idx}) {
          transition-duration: #{1100ms - $idx * 60ms};

        }
      }
    }
  }
}
