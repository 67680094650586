
.android-slider {
  --slide-size: 207px;

  display: flex;
  height: 273px;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__frame {
    width: var(--slide-size);
    height: 273px;
    position: relative;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 273px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 500ms ease;
    transform-style: preserve-3d;

    .slide {
      display: block;
      width: calc(var(--slide-size) - 18px);
      height: 273px;
      margin: 0 9px;
      position: relative;

      &__fill:before {
        display: block;
        content: '';
        position: absolute;
        background: rgba(7, 7, 12, 0.6);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }

      canvas {
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        pointer-events: none;
        z-index: 10;
      }

      .img {
        width: 100%;
        height: 100%;
        position: absolute;
        border: none;
        border-radius: 5px;
        background: rgba(7, 7, 12, 0.6) no-repeat center;
        background-size: cover;
        transition: all 500ms cubic-bezier(0.22, 1, 0.36, 1);
        z-index: 8;
      }

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
        text-align: center;
        color: #fff;
        font-size: 24px;
        line-height: 28px;
        font-family: 'Jura', sans-serif;
        font-style: normal;

        padding: 16px 10px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #07080C 0%, rgba(7, 8, 12, 0) 100%);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 9;
        filter: grayscale(0.0);

      }

      &__fill span {
        background: transparent;
      }

      .svg {
        position: relative;
        width: 100%;
        height: 85%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100px;
          height: 100px;

          path[stroke] {
            stroke: #555565;
            transition: 500ms ease all;
          }
        }
      }

      &.active .svg svg path[stroke] {
        stroke: var(--accent-color);
      }
    }
  }
}
