
@import "src/styles/mixins";

#android-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--full-h);
  background: #07070C;
  z-index: 99;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;

  .wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 300px;
      height: 300px;
      transition: all 500ms ease;
      transform: scale(0.2);
      opacity: 0;
      transition-delay: 100ms;

      @include adapt(tablet) {
        width: 170px;
        height: 170px;
      }

      @include adapt(mobile) {
        width: 120px;
        height: 120px;
      }
    }

    .title {
      font-family: 'quantum', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 100px;
      line-height: 80px;
      letter-spacing: -0.1em;
      position: relative;
      left: 0.1em;
      transition: all 500ms ease;
      transform: scale(0.2);
      opacity: 0;

      @include adapt(tablet) {
        font-size: 80px;
        line-height: 60px;
      }

      @include adapt(mobile) {
        font-size: 40px;
        line-height: 28px;
      }

      span {
        color: #2e2e35;
      }
    }

  }

  &.active {
    opacity: 1;
    visibility: visible;

    .wrap {

      svg {
        transform: none;
        opacity: 1;
      }

      .title {
        transform: none;
        opacity: 1;
      }
    }
  }

}
