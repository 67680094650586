
@import 'src/styles/mixins';

#android-editor {
  position: relative;
  width: 100vw;
  height: var(--full-h);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .chevron-down {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    transition: opacity 500ms ease;
    transition-delay: 500ms;

    &.hidden {
      transition-delay: 0ms;
      opacity: 0;
    }

    svg {
      display: block;
      animation: chevron-small-bounce 1000ms ease-in-out infinite;
    }
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--full-h);
    transition: 500ms ease-in-out all;
    overflow: hidden;

    &.active {
      transform: translateY(-100%);
    }

    &:nth-of-type(2) {
      transform: translateY(100%);

      &.active {
        transform: none;
      }
    }
  }

  button.back {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    font-family: 'Jura', sans-serif;
    padding: 0;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    transition: all 300ms ease;
    z-index: 7;

    svg {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }

    &:active {
      transition-duration: 0ms;
      transform: translateY(1px);
    }

    @include adapt(tablet) {
      font-size: 16px;
      line-height: 16px;
      bottom: 20px;
      left: 20px;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }


    @include adapt(mobile) {
      bottom: auto;
      top: 10px;
      left: 16px;
      font-size: 12px;
      line-height: 12px;

      svg {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }

  }

  .button-wrap{
    position: absolute;
    bottom: 30px;
    left: 30px;
    opacity: 1;
    transition: opacity 0.33s;

    button{
      height:clamp(32px, 2.5vw,42px);
      width: 108px;
    }

    canvas{
      position: absolute;
      top: -5px;
      left: -5px;
      z-index: 1;
    }

    span{
      font-size:clamp(12px, 1.5vw,14px);
    }

    &.disabled{
      opacity: 0;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }


  .mobile-modules {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #07080C;

    &__title {
      width:calc(100% - 70px);
      text-align: center;
      position: relative;
      margin-top: 24px;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #555565;
      border-radius: 8px;

      &-text{
        font-family: 'Jura', sans-serif;
        font-style: normal;
        text-transform: uppercase;
        color: #F6CF00;
        font-weight: 500;
        font-size: clamp(14px, 5vw, 30px);
        line-height: 28px;
        transform: none;
        opacity: 1;

        @keyframes fade-in-title-left{
          0% {opacity: 1; transform: none}
          25% {opacity: 0;transform: translateX(50%)}
          50% {opacity: 0;transform: translateX(-50%)}
          100% {opacity: 1;transform: none}
        }

        @keyframes fade-in-title-right{
          0% {opacity: 1; transform: none}
          25% {opacity: 0;transform: translateX(-50%)}
          50% {opacity: 0;transform: translateX(50%)}
          100% {opacity: 1;transform: none}
        }

        &.active-l{
          animation: fade-in-title-left 0.5s ease-in-out;
        }
        &.active-r{
          animation: fade-in-title-right 0.5s ease-in-out;
        }

      }

    }

    &__wrap {
      width: 100%;
      flex: 1 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 16px;
      column-gap: 17px;
      padding: 12px 17px 17px;
      box-sizing: border-box;

      .test {
        background: #f00;
      }
    }

  }

  .footer {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    display: flex;
    padding: 30px;
    box-sizing: border-box;
    background: linear-gradient(0deg, #07080C 5%, rgba(7, 8, 12, 0) 100%);
    transition: transform 500ms ease;

    &.shift-up {
      transform: translateY(-21px);
    }

    @include adapt(tablet) {
      padding-bottom: 20px;
    }

    @include adapt(mobile) {
      padding-bottom: 40px;
    }

    &__manufacturer {
      position: absolute;
      bottom: 30px;
      right: 30px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      color: #555565;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;

      svg {
        width: 36px;
        height: 36px;
        margin-left: 12px;

        path[fill] {
          fill: #555565;
        }

        path[stroke] {
          stroke: #555565;
        }
      }

      @include adapt(tablet) {
        font-size: 16px;
        line-height: 16px;
        right: 20px;
        bottom: 20px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      @include adapt(mobile) {
        display: none;
      }
    }

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      margin: 0 auto;
      width: 100%;
      max-width: 1000px;
      flex-direction: column;

      .title {
        position: relative;
        left: 0.1em;
        font-family: 'quantum', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 100px;
        line-height: 60px;
        white-space: nowrap;
        letter-spacing: -0.1em;
        margin-bottom: 20px;

        @include adapt(tablet) {
          font-size: 80px;
          line-height: 60px;
          margin-bottom: 15px;
        }

        @include adapt(mobile) {
          font-size: 32px;
          line-height: 28px;
          margin-bottom: 12px;
        }

        span {
          opacity: 0.2;
        }
      }

      .info {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #fff;
        transition: 500ms ease all;
        visibility: hidden;
        transform: translateY(30px);
        opacity: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 15px;
        transition-delay: 100ms;

        @include adapt(tablet) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 10px;
        }

        @include adapt(mobile) {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 8px;
        }

        &.active {
          transform: none;
          visibility: visible;
          opacity: 1;
          transition-delay: 0ms;
        }

      }

      button.show-popup {
        display: block;
        padding: 0;
        white-space: nowrap;
        background: transparent;
        border: none;
        color: var(--accent-color);
        font-family: 'Jura', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        transition: all 500ms ease;
        opacity: 0;
        transform: translateY(30px);
        visibility: hidden;
        transition-delay: 0ms;

        @include adapt(tablet) {
          font-size: 16px;
          line-height: 18px;
        }

        @include adapt(mobile) {
          font-size: 14px;
          line-height: 16px;
        }

        &.active {
          visibility: visible;
          opacity: 1;
          transform: none;
          transition-delay: 100ms;
        }

        &:active {
          transition-duration: 0ms;
          transform: translateY(1px);
        }
      }
    }
  }
}

@keyframes chevron-small-bounce {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: none;
  }
}
