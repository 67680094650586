
@import "variables";
@import "fonts";
@import "mixins";

:root {
  --full-h: 100vh;
  --content-h: 100vh;
}

html, body {
  margin: 0;
  padding: 0;
  height: var(--full-h);
  background: #07070C;
  overflow: hidden;
}

body {
  --accent-color: #DE414A;

  * {
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGDSURBVHgBtZRBTgIxFIb/V0iIu+EGjPEAcALKwkTcODfwCHITwwmMJxA2EuOCegLwADrcwHGFJsw827EQGJioY/snk3l97czX1/4tsKVYygCeRN+AsxYT3ehQ2qQCYwSsnkL1OIcDWVD/jgmRDhP9FKtaEJFKMx7XsFShUgkqiGIZBUyfbwZCzB3gI0lxJAU4AlFX51uFmVWqdhuEd142O4UZx/K8DXCXSYNBEhWrzZfutdef6pckzqJQPYzLBhuzVK02B73I/oAI1xkwPJlOBvil/lItrT/Qg2c6TI6nkyYq6IdqF7SO9PKZfQqIVx0Xlt6ploH6uoMZt3r5rjLUpW7+GxSq+7n9z9C0xQZkNtIkCBfwoA2ohoayQDnzcBVtQKEamTOgTByg0YVjie2G3qf8DKUkenAssdsgZZOXcKwdkHVKfrHG8rQNhxLFhLG5eVubewR5svkeyJfN90C+bC4OJX3YXBxOurf5QZAPm4uyDtc2Lwc5tnkpyNp8kQLPcKAvKw2/6lBGaQIAAAAASUVORK5CYII='), default;
  }

  &.cursor-close * {
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEWSURBVHgB7ZbBEYIwEEV310KETsSTdmEnjlZCF3oSOwEbIeYPRJ0QQiDgiT8jjsnufxKWbIj+JMal3B2SmugkRHn6vFc0g2xPwSAGmPmsmAsEUKTgAS94whtj0l5y/VXpTxILMxB4wbP1bpbOFcBKZWOX0efBoYExkA5oKiwkh6cmjo0VVzICkUBtgZjKcamdS4b+kPQZGJhS6mIqp8cgR8yU4lm1almxbzKkfYS2GPFB7K3epdAWIz4IWVt9jwHmKvppMfoFpkHQ2E3V3q6Q+8qOqQ2TGIgDViK3Zn7YsFkbX4XC0BCw8VOU2m+LW6mf4fdwEgvpgxmvz+FkDgiUwFTfCTXLuO0cTubc6g1Me143nopdRG9grxksAiGUBQAAAABJRU5ErkJggg==') 11 11, default;
  }


  &.robot-2 {
    --accent-color: #01A4FF;

    * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFfSURBVHgBtZTBTcNAEEX/OMCZdJASkgpiKsD3gFA6SCdABRFyOEMqwFQAdJAO4iuKyDCza7CNbZRYO/+QHXk3ev4z3wtUteRzGIkKwIhOsZQqLp5nTHiW9RUzekcAORCl/CRVAkYua90VYyOHsv0Aa3wiw5xy9BBpu8TNVuqcd5joioFzlhBhKidGf/7Ty20VBAENG2/8yGP5nYqzhMrWeh3h1rduxS+yxKztm9G667ALS0+3PgwrXkhxy4x7XNMCh+oItz+pG0v73qBzuqIh+ug/tx5aWEt5q4kT65Mgka64rW+kfCezYm0jDBT9ViypgRvaJQxUgr48CDpUg6uoBPlvIHP1mfTWDATtHvw3tMcFAqsGwq6YE+EGgVUHzSXWerFCLlYfTyMQXPseiiKGJcgq5k2QUcybIKOYR20PLWLeCrKIeTvIIOZR10bomHeCQsf8pHNHY07YiLMPBNA3gUuW0LjRf3cAAAAASUVORK5CYII='), default;
    }

    &.cursor-close * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEHSURBVHgB7ZbhEYMgDIVfqHvUjqKT2Ek6ik7SjlIHsZcSQO9KkUOh/eX7oXeSvE8QEoE/icy15xoVOkwYcKURJeR5KvNQPyDCjSo8TEABiHiJp4FpWZBQGaOeX50NcxDxMp7ijXnpAgE8odm8jBEPSg3MgXyD9sIScmhv4tZYFUzWgSbBbZB55wQlYwkvVK0aCKznZjkLa9JjXNl7sTN46FARUXQ0pX0kthgVg/ilPqjEFqNiEL/UBxVqMcwJoK1F1StXJnfAxYepLIgPA54OdvdhZRvfwBYCgRiPVn9dDSc3oxIQUUfW/HNmtQwtPyfZkDDsPO9Y2yZKl3qBDdzipCGvyI79hd5FMej4cmN7AwAAAABJRU5ErkJggg==') 11 11, default;
    }
  }

  &.robot-3 {
    --accent-color: #16943A;

    * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGUSURBVHgBtZRBTsJAGIXfP6XgsiTimiPAwkTYUE9gb+ABwAgnISSwNp5AOYF1040L8QZslQVdaov9nSlIWqFGS+clTfvPNPPNm3kzQELW0LagSbQGnNVN07ghsK1qBrng6B6CHhddb4YCFINOJu07ObrDDJ8IaVeMORNcYmMarAzXH7g+cojUcpXNYCm/fRFGzXcc+UbpwzYAR7Z1QFRPc/O5TYIQhOXqzxnXJqcNRCUJhEMgGzndxktXG7ce1CCycF573jTrZzWpvG5j0PG41Regodyj0eLK6+OP+o/bTerajbKJZ/npv/W8KnLoV7cSStvZjdtLWVhMYXPRfTo40km3qi4lqLey8VpwyZbVwaDNZNUzUrX47ojA7vqNC2jQFvS5qrjqrTZVx1W0BakzwBtXFTPooGCJZMFAfIYixjkKVgq0CuWBk5L33SUKVgrkD7yZdKWuESuOpy5QLBVz1bGOuT6QrpjvgHTFfAekK+ZiX6OOmO8F6Yj5XpCOmIvMnoJjngkqOuaZoDjmzHMwvaAAfQGG48xeBVydbAAAAABJRU5ErkJggg=='), default;
    }

    &.cursor-close * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE2SURBVHgB7ZaxscIwDIYlH7z6ve51hC2gDJNkA2ACDhYANmASKGELUtJBDbkYyQSIiWMMDtDwF8nZVvSddIpkgDcJ+fE7bgU/NRHtk3S2669iqEC3PgVv8gYhB/ResAF46gTBReYz4j0FEkQFKWM6CHxhVwgGICFWviFLHeufDNKcAYUcPppGAyTcZD4wb+gDs0EKoGdh9yBG0KMwFwhLmD5mQ5HIMFcgEZToVLF2SClIh8HwXDlGB6pilU0p5KuvPiO0HbqMD9cRI+wQvdWb5DpihB2CWqs3OjCNGCkLdugAufvHa70RID4kaWfbXa5pbY7oGQjr0q5ArmkZ1Gti/jdtN/ORoS9Ei2yiIpuT2+ZtZFgVxAILtz3tcuIPYW16Ko2dLI2NevFyUl2rv8AkjGwV+xIdAVjdKmcpDUT9AAAAAElFTkSuQmCC') 11 11, default;
    }
  }

  &.robot-4 {
    --accent-color: #F6CF00;

    * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFiSURBVHgB1VXRTcMwEH2+IIqqfpANOgEiYgBgArIBK3QTYALEBNAJCANEgQ2yQSJRKtGCzdlNSuQSNQ32R58U3fkc+/ldnhWggSLDMTxBVATjAXDP6UVVT/h5ksDLKMIrHMAQzTM8cogVUHLBVpWrFfF0wTGMUKIHhG4XqymwIomOOL6zskAhFgLnXB9baxL0UNskwicQ2ieeZTglGMIYv62t0Vlt3bpnvUkQIB6cYNr2suJD9VVriGYpJkS4kRJ3ozNM0BG7qBWNBRmn5TBCiB7YojYXdfaRodCOO2BDHDqwtKW2MZHilr+VmqfdW7cLqE6kMv3UlSt4wLp122z+X6wVVRsnOh8GprdOQc0B29vcoeUSl3AMskaJCYRr+Ia2uXbfgu0JhyC7oCQedPySGzfdLZEvmwu74MvmG4p82Zz+KvqwObVUExP20ebUNuHa5q1Erm3eSvQdGKKc//9vcIAfT4qg5i2/Jk4AAAAASUVORK5CYII='), default;
    }

    &.cursor-close * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEtSURBVHgB7ZbBjcIwEEW/Z1esuO1WsKEDKCGphE6gFCqBChBQAXQAF4Q4kDBjbBSMEwwOcOFLiSN55j/ZGs0EeKXWMyTbKYayoiG5niSvVo6+Igx+gEkTMPEQL/EU7zOICCNeVvwksTALES/xNN5QNmDHAUUpYA+kfz0NfxjC5mnbeKhyYAysDnIFehR2C+IF3QsLgYjIlyyBkgBTILZyfDJ7SR1E9F1lIAl8sjRnI1s5Pskex+i1fWfxfPTRGxUyPkJHDNUZuK3ep9ARQ3UQOK3ea+AZMUURAArtXVZuu5LczRwdF0YxEA9saWBjF6ZiIWXt5vraxvzZwanrZ79dLJUyJ2oCok/W5VyF7OJkC+fnJBZSAftvHU4Vq8dE061eYHyNWc4Q+qqu2KfoCBjVwF04tCbjAAAAAElFTkSuQmCC') 11 11, pointer;
    }
  }

  &.robot-5 {
    --accent-color: #A849F3;

    * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGbSURBVHgBtVRBTsJAFP3zqRoUY3sDjgAnoC5MxI3daIwsDCeQmxhOQFwYjRthAzEuHE8g3oAbtAYjEMqMM20htFCDdeYt2jd/Zvr6ft8UYAkt2zVBE4i83B+7RUKMlqB2VKcI0Abffzt/sfqgAKFQdfgkiAPAPVFKuCIDsYoyPu1MRkDr1PIgA0jLcc38xHAF9zj3y+MxeLv5nM04OIRARSwpJvZkcrssBKORbyXf+O7kq7TFeUUKC2d2YvvGboPWPVSHr+Jm5xg6Z897nbTFMixZ3UZCnw0AvGHAmpe9gwZsiL+4JeEGt5Tjxrug3kVv34IM+N0tGZA5Fe2T38lE3y+riHTMrYAxnxBtu0XAa2agLYb/Fqp1C/3oOU05xvlEDggNGZ6CBiyEvndmNKK2jl/RQqjeDs4Albyws10BxcD4kAVnaIr+IShGTGhGGA2LeAWKEROqdYNYyxaaj0duCRQCkwUZ8+AexlyfkK6YrwjpivmKkK6Y4/qy+pivFdIR87VCOmKOaROqY54qpDrmqUJhzPmAAXyAAvwA9/7KPgCsx6MAAAAASUVORK5CYII='), default;
    }

    &.cursor-close * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEkSURBVHgB7ZbBDcIwDEXtIO4wATAKnGADuLEJgkl6gw3gBKPQDeCOqImVhpY0TdOmwKVfag6N/Z+UWHYAfiTkZT+/jQHEGiCJVqdhDC3I9BTqt1gjig1i/6ICwiHsxZ4KJle1lUQAFMtvHArTEPZSnuydHl0W0JMBKAMwJnpM6x6jCSF6vj2wGNgM5oIUQE1hVRArqC7MB8IStmQO5ARdILpy7OI9N6QUlIcRJVtdOXYlEce4IJ06/Ufo2vQZH74jRrggZqsvsfAaMcIFMVu9XcURQ0TVIN/epWW2K849LO4TEyZCIBbYNYWdTRiGQvI68B1h7yxtJ2nXny2PgysiZo+TUIgDNv14nLQBYS05l56z9BhHhcdJm61ew6TnDp0V+wW9APEzOyu3+VQbAAAAAElFTkSuQmCC') 11 11, default;
    }
  }


}

#root {
  display: block;
  height: 100%;
  font-family: 'Jura', sans-serif;
}

