
@import "src/styles/mixins";

#about-screen {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;

  .columns {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    @include adapt(mobile) {
      flex-direction: column;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      @include adapt(mobile) {
        align-items: center;
      }

      .types {
        font-size: 60px;
        line-height: 70px;
        font-weight: 300;

        @include adapt(tablet) {
          font-size: 36px;
          line-height: 43px;
          margin-bottom: 16px;
        }

        @include adapt(mobile) {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0;
        }
      }

      .counter {
        font-size: 220px;
        line-height: 160px;
        letter-spacing: -0.2em;
        font-family: 'quantum', sans-serif;

        @include adapt(tablet) {
          font-size: 140px;
          line-height: 80px;
        }

        @include adapt(mobile) {
          font-size: 80px;
          line-height: 60px;
          position: relative;
          left: 0.1em;
        }
      }

    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-left: 50px;

      @include adapt(mobile) {
        margin-top: 40px;
        margin-left: 0;
        align-items: center;
        text-align: center;
        margin-bottom: 13px;
      }

      .title {
        font-family: 'quantum', sans-serif;
        font-size: 100px;
        line-height: 80px;
        letter-spacing: -0.1em;

        @include adapt(tablet) {
          font-size: 80px;
          line-height: 60px;
          margin-bottom: 15px;
        }

        @include adapt(mobile) {
          font-size: 40px;
          line-height: 28px;
          margin-bottom: 5px;
        }
      }

      p {
        max-width: 450px;
        font-size: 18px;
        line-height: 28px;

        @include adapt(tablet) {
          font-size: 16px;
          line-height: 22px;
          max-width: 320px;
        }

        @include adapt(mobile) {
          font-size: 14px;
          line-height: 16px;
          max-width: 290px;
        }
      }

      .button-wrap {
        margin-bottom: 25px;

        @include adapt(tablet) {
          margin-bottom: 5px;
        }

        @include adapt(mobile) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
