
#cursor {
  display: block;
  position: fixed;
  top: 2px;
  left: 2px;
  z-index: 100;
  transform-style: preserve-3d;
  pointer-events: none;
  transition: 50ms ease-out all;

  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    top: 10px;
    left: 10px;
    transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    box-sizing: border-box;
    transform-origin: 6px 6px;
  }

  &.active:after {
    position: absolute;
    background: transparent;
    width: 12px;
    height: 12px;
    top: 12px;
    left: 12px;
    //animation: cursor-spin linear infinite 800ms;
  }

  &.center:after {
    top: -2px;
    left: -2px;
  }
}

@keyframes cursor-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
