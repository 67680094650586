
@import "src/styles/mixins";

.content-block {
  position: relative;
  display: block;
  z-index: 1;
}

.scroller-block {
  position: relative;
  width: 100vw;
  height: var(--full-h);
  color: #fff;

  .dots {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include adapt(mobile) {
      top: auto;
      bottom: 0;
      left: 0;
      right: auto;
      height: 41px;
      width: 100%;
      flex-direction: row;
      background: #07070C;
      box-sizing: border-box;
      border-top: 1px solid #555565;
    }

    &__dot {
      width: 1px;
      height: 24px;
      position: relative;

      @include adapt(mobile) {
        width: 30px;
        height: 100%;
      }

      button {
        position: absolute;
        right: 20px;
        top: 0;
        margin: 0;
        border: none;
        padding: 0;
        background: transparent;
        width: 26px;
        height: 26px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        @include adapt(mobile) {
          position: relative;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        span {
          position: absolute;
          top: 0;
          right: 31px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #fff;
          font-family: 'Jura', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: right;
          transition: 200ms ease;
          visibility: hidden;
          opacity: 0;
          transform: translateX(-5px);

          @include adapt(mobile) {
            display: none;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 4px;
          height: 4px;
          box-sizing: border-box;
          border: 2px solid #555565;
          border-radius: 100px;
          transition: all 200ms ease;
        }

        &.active:after {
          width: 16px;
          height: 16px;
          border-color: var(--accent-color);

          @include adapt(mobile) {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    &:hover .dots__dot button span {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
}

.fog{
  position: fixed;
  top: 0;
  left: 0;
  width: 200vw;
  height: 100vh;
  z-index: 1;
  pointer-events: none;
}

