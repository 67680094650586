@import "src/styles/mixins";

#manifesto-popup {
  width: 100%;

  .manifesto-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--full-h);
    flex-direction: column;
    color: #fff;
    text-align: center;
    position: relative;

    @include adapt(tablet) {
      .svg-wrap {
        transform: scale(0.67);
      }
    }

    @include adapt(mobile) {
      .svg-wrap {
        transform: scale(0.37);
      }
    }

    .title {
      font-family: 'quantum', sans-serif;
      font-size: 100px;
      line-height: 80px;
      letter-spacing: -0.1em;
      position: relative;
      left: 0.14em;
      margin: 40px 0 20px;

      @include adapt(tablet) {
        font-size: 80px;
        line-height: 60px;
        margin: 20px 0 15px;
      }

      @include adapt(mobile) {
        font-size: 40px;
        line-height: 28px;
        margin: 12px 0;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0 0 25px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;

      @include adapt(tablet) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      @include adapt(mobile) {
        font-size: 14px;
        line-height: 16px;
        max-width: 280px;
        margin-bottom: 12px;
      }

      li {
        display: block;
        text-align: center;
        padding: 0;
        margin: 0 0 15px;

        @include adapt(tablet) {
          margin-bottom: 10px;
        }

        @include adapt(mobile) {
          margin-bottom: 8px;
        }
      }
    }

    .chevron-down {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      z-index: 20;
      align-items: center;
      justify-content: center;
      transition: all 400ms ease;
      transform-style: preserve-3d;

      animation: chevron-bounce 2000ms ease-in-out infinite;
    }
  }

  .content {
    color: #fff;
    max-width: 960px;
    margin: 0 auto;
    padding: 20px 30px;
    box-sizing: border-box;

    .title {
      font-family: 'quantum', sans-serif;
      font-size: 100px;
      line-height: 80px;
      letter-spacing: -0.1em;
      position: relative;
      left: 0.14em;
      margin: 40px 0 20px;

      @include adapt(tablet) {
        font-size: 80px;
        line-height: 60px;
        margin: 20px 0 15px;
      }

      @include adapt(mobile) {
        font-size: 40px;
        line-height: 28px;
        margin: 12px 0;
      }

      &-small {
        font-size: 80px;
        font-family: 'quantum', sans-serif;
        line-height: 80px;
        letter-spacing: -0.1em;

        @include adapt(tablet) {
          font-size: 60px;
          line-height: 60px;
          margin: 20px 0 15px;
        }

        @include adapt(mobile) {
          font-size: 30px;
          line-height: 28px;
          margin: 12px 0;
        }
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;

      @include adapt(tablet) {
        font-size: 16px;
        line-height: 22px;
      }

      @include adapt(mobile) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    h1, h2 {
      text-align: center;
      font-weight: 500;
    }

    h1 {
      font-family: 'quantum', sans-serif;
      font-size: 100px;
      line-height: 22px;
    }

    h2 {
      margin-top: 50px;
      font-size: 50px;
      line-height: 70px;
    }

    ul {

      li {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;

        @include adapt(tablet) {
          font-size: 16px;
          line-height: 22px;
        }

        @include adapt(mobile) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

  }


}

@keyframes chevron-bounce {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: none;
  }
}
