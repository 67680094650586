@import "src/styles/mixins";

#android-select-screen {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 1300px;
    height: 500px;

    --normal-width: 237px;
    --condense-width: 217px;
    --extended-width: 317px;
    --block-pad: 28px;

    @include adapt(tablet) {
      width: 870px;
      height: 364px;

      --normal-width: 159px;
      --condense-width: 149px;
      --extended-width: 199px;
      --block-pad: 18px;
    }

    @include adapt(mobile) {
      width: 100%;
      height: 273px;
    }

    .title {
      text-align: center;
      font-family: 'quantum', sans-serif;
      font-size: 100px;
      line-height: 80px;
      letter-spacing: -0.1em;
      position: absolute;
      bottom: -20px;
      left: 0.1em;
      width: 100%;
      transform: translate(-0.1em, 100%);

      @include adapt(tablet) {
        font-size: 80px;
        line-height: 60px;
      }

      @include adapt(mobile) {
        font-size: 40px;
        line-height: 28px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      width: var(--normal-width);
      height: 500px;
      box-sizing: border-box;
      padding: 0;
      border: none;
      background: transparent;
      transition: all 500ms cubic-bezier(0.22, 1, 0.36, 1);
      position: absolute;
      top: 0;
      left: 0;

      @include adapt(tablet) {
        height: 364px;
      }

      canvas {
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        pointer-events: none;
      }

      img, video {
        width: 100%;
        height: 100%;
        position: absolute;
        border: none;
        background: rgba(7, 7, 12, 0.6);
        border-radius: 5px;
        object-fit: cover;
        transition: all 500ms cubic-bezier(0.22, 1, 0.36, 1);
      }

      span {
        display: block;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: absolute;
        bottom: 0;
        left: 1px;
        width: calc(100% - 2px);
        overflow: hidden;

        em {
          display: block;
          text-align: center;
          color: #fff;
          font-size: 30px;
          line-height: 30px;
          font-family: 'Jura', sans-serif;
          font-style: normal;

          padding: 30px 10px;
          box-sizing: border-box;
          background: linear-gradient(0deg, #07080C 0%, rgba(7, 8, 12, 0) 100%);;
          transform: translateY(100%);
          transition: transform 500ms ease;

          @include adapt(tablet) {
            padding: 24px 20px;
            font-size: 24px;
            line-height: 24px;
          }
        }


      }

      &.condensed {
        width: var(--condense-width);

        img {
          filter: grayscale(80%) brightness(0.6);
        }
      }

      &.wide {
        width: var(--extended-width);

        span em {
          transform: none;
        }
      }
    }
  }
}
