
#graphics-background {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100vw;
  height: var(--content-h);
  z-index: 0;
  overflow: hidden;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .fps {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    background: #444444;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
  }
}
