
#login-menu {
  display: flex;
  background: #07070C;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  visibility: hidden;
  transition: 300ms ease all;
  transform: translateX(100%);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  color: #fff;

  &.active {
    visibility: visible;
    transform: none;
  }

  .title {
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
  }

  .sub {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin-bottom: 15px;
    }
  }
}
