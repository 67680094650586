@import "src/styles/mixins";

#robot-popup{
  width: 100%;

  .title{
    margin-bottom: 20px;
  }

  .manifesto-block{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    color: #fff;
    text-align: center;
    position: relative;
  }

  .content{
    color: #fff;
    max-width: 960px;
    margin: 0 auto;
    padding: 20px 30px;
    box-sizing: border-box;

    .title{
      text-align: center;
      font-family: 'quantum', sans-serif;
      font-weight: 500;
      font-size: 100px;
      line-height: 80px;
      letter-spacing: -0.1em;
      @include adapt(tablet) {
        font-size: 80px;
        line-height: 60px;
        margin: 20px 0 15px;
      }

      @include adapt(mobile) {
        font-size: 40px;
        line-height: 28px;
        margin: 12px 0;
      }
      &-sub{
        color: #FFFFFF;
        opacity: 0.6;
        font-size: 18px;
        text-align: center;
        @include adapt(tablet) {
          font-size: 14px;
          line-height: 20px;
        }

        @include adapt(mobile) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }


    .text{
      font-family: 'Jura', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;

      @include adapt(tablet) {
        font-size: 16px;
        line-height: 22px;
      }

      @include adapt(mobile) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .samples{

      p {
        text-align: center;
      }

      .pictures{
        display: flex;
        justify-content: space-between;
        gap: 15px;


        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: center;
        }

        em {
          padding: 12px;
          position: relative;
          flex: 1 1;
          display: block;
          border: 1px solid #555565;
          border-radius: 8px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;

          //@media screen and (max-width: 600px) {
          //  width: 100%;
          //  max-width: 255px;
          //  margin-bottom: 20px;
          //}

          &-description{
            display: flex;
            justify-content: space-between;

            font-family: 'Jura', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;


            @include adapt(tablet) {
              font-size: 14px;
              line-height: 20px;
            }

            @include adapt(mobile) {
              font-size: 12px;
              line-height: 14px;
            }

            &-left{
              color: #555565;

             p{
               margin: 8px 0;
               &:first-child{
                 margin-top: 5px;
               }
               &:last-child{
                 margin-bottom: 15px;
               }
             }

            }

            &-right{
              text-align: right;

              p{
                margin: 8px 0;
                &:first-child{
                  margin-top: 5px;
                }
                &:last-child{
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
    }



  }
}

.toxic {
  color: #25FF02;
}
